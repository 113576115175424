@import "../ui/Theme";

.order-list .card {
  background-color: $starter-list-card-bg-color;
  border: solid 1px $starter-list-card-border-color;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 30px $starter-list-card-box-shadow-color;
  border-top: none;
}

.order-list .card .card-body{
  padding: 0;
}

.order-list .list-group {
  border-style: hidden;
  border-radius: 5px;
}

.order-list .list-group-item {
  background-color: $starter-list-table-tr-starter-list-item-row-td-bg-color;
}

.order-list .list-group-item:nth-child(odd) {
  background-color: $starter-list-table-tr-starter-list-item-row-td-odd-bg-color;
}

.order-list .list-group-item:hover {
  background-color: $starter-list-table-tr-hover-td-bg-color!important;
}
