#forbidden-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

#forbidden-container .box {
    max-width: 800px;
    display: flex;
    align-items: center;
}

#forbidden-container .image-container {
    
    flex-basis: 0;
    flex-grow: 1;
}

#forbidden-container .text-container {
    flex-basis: 0;
    flex-grow: 1;
    text-align: right;
    padding-right: 20px;
}

#forbidden-container h1 {
    font-size: 10rem;
    font-weight: 600;
    line-height: 9rem;
}

#forbidden-container p {
    font-size: 1.25rem;
}

@media only screen and (orientation:portrait) and (max-width: 800px) {
    #forbidden-container {
        min-height: 500px;
    }
    
    #forbidden-container .box {
        flex-direction: column-reverse;
        padding: 40px;
        max-width: 500px;
    }

    #forbidden-container .image-container {
        max-height: 300px;
        height: calc(50vh - 60px);
        width: 100%;
    }

    #forbidden-container .image-container img {
        max-height: 100%;
    }

    #forbidden-container .box h1 {
        margin-top: 40px;
    }

    #forbidden-container .box h1, #forbidden-container .box p {
        text-align: center;
    }
}