@import "./Theme.scss";

#top-navbar {
    padding-top: 10px;
    padding-bottom: 10px;
}

.top-navbar-user-dropdown .dropdown-toggle::after{
    display: none;
}

.top-navbar-user-dropdown > a {
    font-size: 1rem;
    font-weight: 500;
    color: $top-navbar-user-dropdown-a-color;
}

.top-navbar-user-dropdown > a {
    padding-right: 10px!important;
}

.top-navbar-user-dropdown > a:hover, .top-navbar-language-dropdown > a:hover {
    background-color: $top-navbar-user-dropdown-a-hover-bg-color;
    border-radius: 3px;
}

.top-navbar-language-dropdown .dropdown-toggle::after{
    display: none;
}

#top-navbar .navbar-nav.ml-auto > a{
    margin-left: 10px;
}

.top-navbar-language-dropdown > a {
    line-height: 31px;
    padding: 13.5px!important;
}

.dropdown-item {
    padding: .75rem 1.75rem;
}

#top-navbar .dropdown-menu {
    box-shadow: 0px 0px 50px 0px $top-navbar-dropdown-menu-box-shadow-color;
    border-radius: 5px;
    border-color: $top-navbar-dropdown-menu-border-color;
}

#top-navbar .nav-link{
    line-height: 31px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: $top-navbar-nav-link-color;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
}

#top-navbar .nav-link svg {
    margin-right: 5px;
}

#top-navbar .nav-link:hover {
    color: $top-navbar-nav-link-hover-color;
    background-color: $top-navbar-nav-link-hover-bg-color;
    transition: all .25s ease-out;
}

#top-navbar .dropdown-item{
    color: $top-navbar-dropdown-item-color;
}

#top-navbar-nav {
    width: 100%;
}

#top-navbar-nav .navbar-nav {
    flex-direction: row;
    justify-content: flex-end;
}

@media (max-width: 767.98px) {
    #top-navbar-nav .navbar-nav .dropdown-menu {
        position: absolute;
    }
}