@import "./components/ui/Theme.scss";
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap');

html, body {
    font-size: 13px;
}

body {
    overflow-x: hidden;    
}

.btn[class^="btn-light"], .btn[class*=" btn-light"]{
    border-color: transparent!important;
    box-shadow: none!important;
}

.btn {
    border-color: transparent!important;
}

.btn-bold {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.btn.btn-light-success {
    background-color: rgba(10, 187, 135, 0.1);
    color: $success;
}

.btn.btn-light-success:hover, .btn.btn-light-success:active {
    background-color: $success;
    color: white!important;
}

.btn.btn-light-primary {
    background-color: rgba(88, 103, 221, 0.1);
    color: $primary;
}

.btn.btn-light-primary:hover, .btn.btn-light-primary:active {
    background-color: $primary;
    color: white!important;
}

.btn.btn-light-warning {
    background-color: rgba(255, 184, 34, 0.1)!important;
    color: $warning!important;
}

.btn.btn-light-warning:hover, .btn.btn-light-warning:active {
    background-color: $warning!important;
    color: #111111!important;
}

.btn.btn-light-danger {
    background-color: rgba(253, 57, 122, 0.1);
    color: $danger;
}

.btn.btn-light-danger:hover, .btn.btn-light-danger:active {
    background-color: $danger;
    color: white!important;
}

.btn.btn-light-dark {
    background-color: $btn-light-dark-bg-color !important;
    color: $btn-light-dark-color !important;
}

.btn.btn-light-dark:hover, .btn.btn-light-dark:active {
    background-color: $btn-light-dark-hover-bg-color !important;
    color: $btn-light-dark-hover-color!important;
}

.dropzone{
    border: dashed 5px $dropzone-border-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    border-radius: 5px;
  }
  
  .dropzone--isActive{
    border-color: $dropzone-active-border-color;
    background-color: $dropzone-bg-color;
  }
  
  .dropzone p{
    margin: 0;
    font-weight: 500;
    color: $dropzone-p-color;
  }