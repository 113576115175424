@import "./Theme.scss";

#sidebar {
    min-height: 100vh;
    flex-shrink: 0;
    -webkit-transition: width .25s ease-out;
    -moz-transition: width .25s ease-out;
    -o-transition: width .25s ease-out;
    transition: width .25s ease-out;
    background-color: $sidebar-bg-color;
    width: 265px;
    overflow: hidden;    
}

#sidebar-toggle-lg {
    display: none;
}

#sidebar:not(.sidebar-open-xl) {
    width: 70px;
}

#sidebar:not(.sidebar-open-xl) .brand-image {
    opacity: 0;
    transition: opacity .25s ease-out;
}

#sidebar nav, #sidebar .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
}

#sidebar > nav {
    padding: 0px;
}

#sidebar > nav .navbar-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $navbar-brand-bg-color;
    width: 100%;
    padding: 0px;
    margin: 0;
    line-height: 60px;
    position: relative;
    height: 60px;
}

#top-menu-toggle, #sidebar-menu-toggle {
    display: none;
    border: none;
    background-color: $top-menu-toggle-bg-color;
    padding: 0;
    margin: 0;
    width: 44px;
    height: 44px;
    color:$top-menu-toggle-color;
}

#menu-container {
    width: 265px;
}

#sidebar-toggle-xl, #sidebar-toggle-lg {
    padding: 0px;
    margin: 0px;
    background-color: $top-menu-toggle-bg-color;
    border: none;
    height: 44px;
    width: 44px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 13px;
}


@media (max-width: 991.98px) {
    #sidebar {
        width: 70px;
    }

    #sidebar-toggle-xl {
        display: none;
    }

    #sidebar-toggle-lg {
        display: flex;
    }
    
    #sidebar.sidebar-open-lg {
        width: 265px;
    }

    #sidebar.sidebar-open-lg .brand-image {
        opacity: 1;
    }

    #sidebar:not(.sidebar-open-lg) .brand-image {
        opacity: 0;
    }
}
@media (max-width: 767.98px) {
    #sidebar {
        min-height: auto;
        width: 100%!important;
    }

    #menu-container {
        width: 100%;
    }
    
    #sidebar-toggle-lg {
        display: none;
    }

    #sidebar nav {
        flex-direction: row;
    }

    #top-menu-toggle, #sidebar-menu-toggle {
        display: inline-block;
    }

    #sidebar .brand-image {
        opacity: 1!important;
    }
}