@import '../../components/ui/Theme.scss';

.column-container {
    height: 100vh;
	min-height: 600px;
}

.column-container .aside {
    background-color: $column-container-aside-color;
    height: 100%;
    width: calc(100% - 480px);
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.control-column{
	width: 480px;
	float: right;
	height: 100%;
	padding: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.alt-logo{
	display: none;
}

@media only screen and (max-width : 650px) {
	.column-container .aside{
		display: none!important;
	}
	.control-column{
		float: none;
		width: auto;
	}
	.alt-logo{
		display: block;
	}
}
