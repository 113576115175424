.color-picker-form-group {
  div.form-group {
    padding-left: 0;
    padding-right: 0;
  }

  .color-picker-input {
    text-transform: uppercase;
  }

  .color-picker-input::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  .color-picker-input::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
  }
  .color-picker-input::placeholder { /* Recent browsers */
    text-transform: none;
  }
}
