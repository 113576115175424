.btn-loading {
    position: relative;
}

.btn-loading .loading-indicator {
    opacity: 0;
    transition: opacity .25s ease-out;
    width: 30px;
    height: 24px;
    text-align: 'center';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -15px;
}

.btn-loading.loading .loading-indicator {
    opacity: 1;
}

.btn-loading.loading > span {
    opacity: 0;
}