@import "./Theme.scss";

#sidebar-container {
    display: flex;
}

#content-container {
    flex-grow: 1;
}

#main-container {
    padding: 30px;
}

#top-navbar .navbar-text {
    font-weight: 500;
    font-size: 1.25rem;
    color: $dark;
    flex-shrink: 0;
}

@media (max-width: 767.98px) {
    #sidebar-container {
        flex-direction: column;
    }

    #top-navbar {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    #main-container {
        padding: 15px;
    }
    
}