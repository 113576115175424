@import '../../components/ui/Theme.scss';

#activation-container {
    display: flex;
    min-height: 100vh;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

#activation-container .box {
    
    width: 800px;
    display: flex;
    align-items: center;
}

#activation-container .image-container {
    padding: 20px;
    flex-basis: 0;
    flex-grow: 1;
    max-height: calc(100vh - 80px);
    height: 100vh;
    position: relative;
}

#activation-container .image-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
}

#activation-container .text-container {
    flex-basis: 0;
    flex-grow: 1;
    text-align: right;
    padding-right: 20px;
}

#activation-container h1 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 4rem;
    color: $primary;
}

#activation-container p {
    font-size: 1.25rem;
}

@media only screen and (orientation:portrait) and (max-width: 800px) {
    #activation-container {
        height: 520px;
    }
    
    #activation-container .box {
        flex-direction: column-reverse;
        padding: 40px;
        max-width: 500px;
    }

    #activation-container .text-container {
        padding: 0;
    }

    #activation-container .image-container {
        max-height: 300px;
        height: calc(50vw - 60px);
        width: 100%;
        padding: 0px;
        flex-basis: calc(50vw - 60px);
    }

    #activation-container .image-container img {
        max-height: 100%;
    }

    #activation-container .box h1 {
        margin-top: 40px;
    }

    #activation-container .box h1, #activation-container .box p {
        text-align: center;
    }
}